import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import {
  HeapEvents,
  islamicMonthNames,
  monetaryAbbreviations,
  randomLocations,
} from './constants';
import {
  CurrencyCodeToSymbol,
  Currency,
  CurrencyObject,
  SelectOption,
  PasswordAnalysis,
  LocaleToCurrency,
  BillingAddress,
  InputCurrencies,
  CampaignCardProps,
  GivingMemberEntry,
  CurrencyFormattedAmount,
  SubscriptionObject,
  SubscriptionRawResponse,
} from '../types';
import { current_user_result } from './api';
import { HeapTrackMetadata } from './interfaces/heap.interface';
import {
  ApplePayAdress,
  GooglePayAddress,
} from '../components/PaymentMethod/interfaces';
import config from '@/src/config';
import {
  ramadanChallange10Days,
  ramadanChallange30Days,
} from './constants/sg-constants';
import { currencies } from '../app/scheduled-giving/ramadan/constants';
import { forEach, set } from 'traverse';
import { camelCase } from 'change-case';

export function mergeClasses(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function shortenNumber(num: number) {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return formatter.format(num);
}

export function shortenCurrencyString(currencyString: string): string {
  const numericValue = parseFloat(currencyString.replace(/[^0-9.-]+/g, ''));
  const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
  const formattedNumber = formatter.format(numericValue);
  const currencySymbol = currencyString.match(/[^0-9.-]+/g)?.[0].trim() || '';
  return `${currencySymbol}${formattedNumber}`;
}

/**
 *
 * @param date
 * @returns a string like "2 {timePeriod} ago" or "just now"
 * @example getTimePassedString(new Date('2021-01-01T00:00:00.000Z')) // "3 years ago"
 */
export function getTimePassedString(date: Date) {
  const now = Date.now();
  const diff = now - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${Math.floor(years)} year${years > 1 ? 's' : ''} ago`;
  }

  if (months > 0) {
    return `${Math.floor(months)} month${months > 1 ? 's' : ''} ago`;
  }

  if (weeks > 0) {
    return `${Math.floor(weeks)} week${weeks > 1 ? 's' : ''} ago`;
  }

  if (days > 0) {
    return `${Math.floor(days)} day${days > 1 ? 's' : ''} ago`;
  }

  if (hours > 0) {
    return `${Math.floor(hours)} hour${hours > 1 ? 's' : ''} ago`;
  }

  if (minutes > 0) {
    return `${Math.floor(minutes)} minute${minutes > 1 ? 's' : ''} ago`;
  }

  return 'just now';
}

export function GregorianToHijri(
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) {
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    calendar: 'islamic-umalqura',
    ...options,
  };
  const format = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = format.format(date);
  const parts = formattedDate.split('/');

  const islamicMonthNumber = parseInt(parts[0]) - 1;
  const islamicMonth = islamicMonthNames[islamicMonthNumber];
  return islamicMonth + ' ' + parts[1] + ', ' + parts[2].replace(/\D/g, '');
}

export function getIslamicDay(date = new Date()) {
  const hijriDate = new Intl.DateTimeFormat('en-US', {
    calendar: 'islamic-umalqura',
  }).format(date);
  return hijriDate.split('/')[1];
}

export function getIslamicMonth(date = new Date()) {
  const hijriDate = new Intl.DateTimeFormat('en-US', {
    calendar: 'islamic-umalqura',
  }).format(date);
  const month = parseInt(hijriDate.split('/')[0]);
  return islamicMonthNames[month - 1];
}

export function getRamadanCompetitionDay() {
  let end = DateTime.fromJSDate(getRamadanContestEndTime());
  const now = DateTime.fromJSDate(getDateInTimezone('America/New_York'));
  const remaining = end.diff(now);
  if (remaining.as('seconds') < 0) {
    end = end.plus({ days: 1 });
  }
  return getIslamicDay(end.toJSDate()).toString();
}

export function getCurrentIslamicMonth(date = new Date()) {
  const hijriDate = new Intl.DateTimeFormat('en-US', {
    calendar: 'islamic-umalqura',
  }).format(date);
  const month = hijriDate.split('/')[0];

  return month;
}

export function ramadanActiveCheck(date = new Date()) {
  return getCurrentIslamicMonth(date) === '9';
}

export function dhActiveCheck(date = new Date()) {
  return getCurrentIslamicMonth(date) === '12';
}

export function isDHCReferralsActive(date = new Date()) {
  const hijriDate = new Intl.DateTimeFormat('en-US', {
    calendar: 'islamic-umalqura',
  }).format(date);
  const parts = hijriDate.split('/');
  const month = parts[0];
  const day = parseInt(parts[1], 10);

  // from 2 months prior to DHC, until the 10th of DHC
  return month === '10' || month === '11' || (month === '12' && day <= 10);
}

export function isRamadanReferralsActive(date = new Date()) {
  const hijriDate = new Intl.DateTimeFormat('en-US', {
    calendar: 'islamic-umalqura',
  }).format(date);
  const parts = hijriDate.split('/');
  const month = parts[0];

  // from 2 months prior to Ramadan, until the whole month of Ramadan
  return month === '7' || month === '8' || month === '9';
}

export function isChallengeActive(startDate: Date, endDate: Date) {
  const now = moment();
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  return startMoment.isBefore(now) && endMoment.isAfter(now);
}

export function isAllowedToEnterChallenge(startDate: Date, endDate: Date) {
  const monthDifference = moment().diff(startDate, 'months');
  // If plan is active or it's going to be active in 2 months allow user to enter challenge.
  return monthDifference <= 2 || isChallengeActive(startDate, endDate);
}

export function getIslamicDate(date = new Date()) {
  const hijriFormatter = new Intl.DateTimeFormat('en-US', {
    calendar: 'islamic-umalqura',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  const hijriDate = hijriFormatter.format(date);
  const [month, day, year] = hijriDate
    .split('/')
    .map((part) => parseInt(part, 10));

  return { month, day, year };
}

export function getDateInTimezone(timezone: string): Date {
  const date = new Date();
  const dateString = date.toLocaleString('en-US', { timeZone: timezone });
  return new Date(dateString);
}

export function hasDatePassed(date: string, timezone = 'America/New_York') {
  const targetDate = moment.tz(date, timezone);
  return moment().isAfter(targetDate);
}

export function getRamadanContestEndTime() {
  const contestEndTime = getDateInTimezone('America/New_York'); // EDT timezone
  contestEndTime.setHours(19, 0, 0, 0);
  return contestEndTime;
}

export function MulticulturalDate(dateString: string) {
  // Parse the input date string into a JavaScript Date object
  const date = new Date(dateString);

  // Convert the Gregorian date to the Islamic (Hijri) date
  const hijriDate = GregorianToHijri(new Date(dateString));

  // Format the Islamic date according to the desired format
  const islamicParts = hijriDate.split(' ');
  const islamicMonth = islamicParts[0];
  const islamicDay = islamicParts[1].slice(0, -1);
  const islamicYear = islamicParts[2];

  // Get the Gregorian date parts
  const gregorianMonth = date.toLocaleString('en-US', { month: 'long' });
  const gregorianDay = date.getDate();
  const gregorianYear = date.getFullYear(); // Subtract 1 year for Islamic year
  // Format the output string
  const output = `${gregorianMonth} ${gregorianDay}, ${gregorianYear} | ${islamicDay} ${islamicMonth}, ${islamicYear}`;
  return output;
}

export function GetIslamicYear(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    calendar: 'islamic-rgsa',
    // ...options,
  };
  const format = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = format.format(date);
  const parts = formattedDate.split('/');

  // Extract the Islamic year without the suffix and any non-numeric characters
  const islamicYear = parts[2].replace(/[^\d]/g, ''); // Remove non-numeric characters

  return islamicYear;
}

/**
 * @param {number} amount
 * @param {Currency} currency
 * @param {0 | 2} minimumFractionDigits 0 for integer, 2 for decimal to ensure trailing zeros are shown
 * @param {0 | 2} maximumFractionDigits 0 for integer, 2 for decimal
 * @returns {string} e.g. $1,000.00 USD or $1,000 USD
 */
export function toCurrency(
  amount: number,
  currency: Currency,
  minimumFractionDigits: 0 | 2 = 0,
  maximumFractionDigits: 0 | 2 = 0,
) {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount ? amount : 0);
}

export function getDaysLeft(date: string): number {
  const daysToEndDate = Math.ceil(DateTime.fromISO(date).diffNow('days').days);
  return daysToEndDate;
}

export function formatedTime(timestamp: string) {
  const date = new Date(timestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${amPm}`;

  return formattedTime;
}

export function dateToFormatWithTimezone(
  date: Date | string,
  format: string,
  timezone: string,
) {
  return moment(date).tz(timezone).format(format);
}

export function formatedDate(
  timestamp: string,
  options?: Intl.DateTimeFormatOptions,
) {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...options,
  });

  return formattedDate;
}

export function formatDateDifference(dateString: string): string {
  const formatedDate = moment(dateString).fromNow();
  return formatedDate;
}

/**
 * @param {number} amount
 * @param {Currency} currency
 * @param {boolean} withDecimal
 * @returns {CurrencyObject} e.g. { amount: 1000, amountString: '$1,000.00', currencyCode: 'USD', currencySymbol: '$' }
 */
export function toCurrencyObject(
  amount: number,
  currencyCode: Currency,
  withDecimal: boolean,
): CurrencyObject {
  if (!withDecimal) {
    amount = Math.ceil(amount);
  }
  const amountString = toCurrency(
    amount,
    currencyCode,
    withDecimal ? 2 : 0,
    withDecimal ? 2 : 0,
  );

  return {
    amount,
    amountString,
    currencyCode,
    currencySymbol: CurrencyCodeToSymbol[currencyCode],
  };
}

/**
 * @param {number} amount
 * @param {Currency} currency
 * @param {0 | 2} minimumFractionDigits 0 for integer, 2 for decimal to ensure trailing zeros are shown
 * @param {0 | 2} maximumFractionDigits 0 for integer, 2 for decimal
 * @returns {string} e.g. $1,000.00 USD or $1,000 USD
 */
export function toCurrencyWithFloor(
  amount: number,
  currency: Currency,
  minimumFractionDigits: 0 | 2 = 0,
  maximumFractionDigits: 0 | 2 = 0,
) {
  return toCurrency(
    Math.floor(amount),
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  );
}

export function getCurrencyUsingLocale(locale: string): Currency {
  return LocaleToCurrency[locale];
}

export const mapToBillingAddress = (
  sourceAddress: GooglePayAddress | ApplePayAdress | undefined,
  type: 'google' | 'apple',
  save: boolean,
): BillingAddress => {
  if (!sourceAddress) {
    return { Save: save };
  }

  if (type === 'google') {
    const googlePayAddress = sourceAddress as GooglePayAddress;
    return {
      Name: googlePayAddress.name,
      Address: googlePayAddress.address1,
      Address2: googlePayAddress.address2,
      State: googlePayAddress.administrativeArea,
      City: googlePayAddress.locality,
      Zip: googlePayAddress.postalCode,
      Country: googlePayAddress.countryCode,
      Save: save,
    };
  }

  // If not a GooglePayAddress, assume it's an ApplePayAddress
  const applePayAddress = sourceAddress as ApplePayAdress;
  return {
    Name: `${applePayAddress.givenName} ${applePayAddress.familyName}`,
    Address: applePayAddress.addressLines?.join(', '),
    State: applePayAddress.administrativeArea,
    City: applePayAddress.locality,
    Zip: applePayAddress.postalCode,
    Country: applePayAddress.countryCode,
    Save: save,
  };
};

export const expiryDateGenerator = () => {
  const futureDate = moment().add(2, 'months');
  const formattedDate = futureDate.format('MMYYYY');
  return formattedDate;
};

/**
 * 3.0 DB returns image URLs without the protocol from '//media.launchgood.com'
 * This function prepends the protocol if it's missing
 * @returns {string} The image source with the protocol prepended if it's missing
 */
export function normaliseImageSrc(src: string) {
  if (src.startsWith('//')) {
    src = `https:${src}`;
  }

  return src;
}

/**
 *
 * @param a {Array<any>}
 * @returns the shuffled array
 */
export function shuffle(a: Array<any>) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function getNextFridayDate() {
  const today = new Date();
  let daysUntilFriday = (5 - today.getDay() + 7) % 7; // 5 represents Friday
  if (daysUntilFriday === 0) daysUntilFriday = 7; // Ensure it's the next Friday, not today if today is Friday

  const nextFriday = new Date(today);
  nextFriday.setDate(today.getDate() + daysUntilFriday);

  return nextFriday.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function toProperCaseUnderscoreSeparated({ text }: { text: string }) {
  return text
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

export function formatMonetaryValue(
  inputNum: number,
  currency?: string,
): string {
  if (isNaN(inputNum)) {
    throw new Error('Invalid input. Please provide a valid number.');
  }

  if (inputNum < 1000) {
    return currency
      ? toCurrency(inputNum, currency as Currency, 2, 2)
      : inputNum.toFixed(2);
  }

  let index;
  for (index = monetaryAbbreviations.length - 1; index > 0; index--) {
    if (inputNum >= monetaryAbbreviations[index].v) {
      break;
    }
  }

  const intValue = Math.floor(inputNum / monetaryAbbreviations[index].v);
  const decimalPart = inputNum / monetaryAbbreviations[index].v - intValue;
  let truncatedValue = `${intValue}.${decimalPart.toString().substring(2)}`;

  truncatedValue = currency
    ? toCurrency(+truncatedValue, currency as Currency, 2, 2)
    : parseFloat(truncatedValue).toFixed(2);

  return truncatedValue + monetaryAbbreviations[index].s;
}

export function getCountryFlag(countryCode: string) {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) =>
      String.fromCodePoint(char.charAt(0).charCodeAt(0) + 127397),
    );
}

/**
 *
 * @param startingYear {number} The year to start from, e.g. 2021
 * @returns {Array<number>} An array of years since the starting year, e.g. [2021, 2022, 2023, 2024]
 */
export function getArrayOfYearsSince(startingYear: number) {
  const currentYear = new Date().getFullYear();
  const yearsArray: number[] = [];

  for (let year = startingYear; year <= currentYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
}

export function createSelectOptions(
  labels: any[],
  values: any[],
): SelectOption[] {
  return labels.map((label, index) => ({
    label,
    value: values[index],
  }));
}

export function getRandomFromArray<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function getUrlWithParams(
  url: string,
  params?: {
    referralCode?: string;
    campaignId?: number;
    referralSource?: string;
  },
) {
  params?.referralCode ? (url = url + `code=${params?.referralCode}&`) : url;
  params?.campaignId ? (url = url + `campaignId=${params?.campaignId}&`) : url;
  params?.referralSource ? (url = url + `src=${params?.referralSource}&`) : url;
  return url;
}

//The `code` property of currency has the flag of the currency along with the currency code. This function returns the currency code.
export function getCurrencyCode(currency: InputCurrencies): string {
  return currency.code.slice(5);
}

export function getCurrencySymbol(
  currencyCode: string,
  currencies: InputCurrencies[],
) {
  const selectedCurrency: InputCurrencies =
    currencies.find((currency) => {
      return getCurrencyCode(currency) === currencyCode;
    }) || currencies[0];

  return selectedCurrency.symbol;
}

export const getCurrencyFromInputCurrencies = (
  providedCurrencies: InputCurrencies[],
  currencyCode: string,
): InputCurrencies => {
  const returnVal =
    providedCurrencies.find((currency) => {
      return getCurrencyCode(currency) === currencyCode;
    }) || providedCurrencies[0];

  return returnVal;
};

//old function used by the old Tip component, will be deprecated
export function getTipDropdownValue(
  tipAmount: number,
  donationAmount: number,
): string {
  const percentageOptions = [10, 20, 30];
  if (tipAmount === 0 && donationAmount === 0) {
    return percentageOptions[0].toString();
  }

  if (isNaN(tipAmount) || isNaN(donationAmount)) {
    return percentageOptions[0].toString();
  }

  const percentage = (tipAmount / donationAmount) * 100;
  const roundedPercentage = Math.round(percentage);

  if (percentageOptions.includes(roundedPercentage)) {
    return roundedPercentage.toString();
  }
  return 'custom';
}

export function calculatePercentage(amount: number, total: number): string {
  return Math.round((amount / total) * 100).toLocaleString();
}

export function analyzePassword(password: string): PasswordAnalysis {
  const upperCaseRegex = /[A-Z]/;
  const lowerCaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const symbolRegex = /[-#!$@£%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/;

  const criteria = [
    { regex: upperCaseRegex, prop: 'haveUpperCase' },
    { regex: lowerCaseRegex, prop: 'haveLowerCase' },
    { regex: numberRegex, prop: 'haveNumber' },
    { regex: symbolRegex, prop: 'haveSpecialChar' },
    {
      prop: 'haveMinLength',
      check: password.length >= 8 && password.length <= 64,
    },
  ];

  const analysis: PasswordAnalysis = criteria.reduce(
    (result, { regex, prop, check }) => {
      (result as any)[prop] = regex ? regex.test(password) : check;
      if ((result as any)[prop]) result.score += 20;
      return result;
    },
    {
      length: password.length,
      haveLowerCase: false,
      haveNumber: false,
      haveSpecialChar: false,
      haveUpperCase: false,
      haveMinLength: false,
      haveAll: false,
      score: 0,
    },
  );

  analysis.haveAll =
    analysis.haveUpperCase &&
    analysis.haveLowerCase &&
    analysis.haveNumber &&
    analysis.haveSpecialChar &&
    analysis.haveMinLength;

  return analysis;
}

export function getTimezoneAbbreviation() {
  const timezoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
  if (/^[+-]\d+$/.test(timezoneAbbr)) {
    return `UTC ${timezoneAbbr}`;
  }
  return timezoneAbbr;
}

export function getTotalRamadanDonation({
  dayOfRamadan,
  plan,
  donationAmount,
  tipAmount,
  additionalDonationAmount,
}: {
  dayOfRamadan: number;
  plan: string;
  donationAmount: number;
  tipAmount: number;
  additionalDonationAmount: number;
}): number {
  let val = 0;

  //dayIndexBound is a 0-indexed counter of Ramadan, e.g. 0 = Ramadan 1
  const daysOfRamadanToCharge =
    dayOfRamadan <= 0 //Ramadan has not started yet
      ? 30 //charge for 30 days
      : 30 - dayOfRamadan + 1; //otherwise, charge the remaining days of Ramadan

  const lastTenNightsOfRamadanToCharge =
    dayOfRamadan <= 20 //Last 10 nights have not started yet
      ? 10 //charge for 10 nights
      : 30 - dayOfRamadan + 1; //otherwise charge the last 10 nights

  if (plan === '30') {
    val = daysOfRamadanToCharge * (donationAmount + tipAmount);
    if (additionalDonationAmount) {
      val += lastTenNightsOfRamadanToCharge * additionalDonationAmount;
    }
  } else {
    val =
      lastTenNightsOfRamadanToCharge *
      (donationAmount + tipAmount + additionalDonationAmount);
  }

  return val;
}

//returns 0 if subscription is before or after Ramadan
//otherwise returns the Ramadan day that the donor has subscribed during Ramadan
export function getDaysSubscribedInRamadan(
  subscriptionDate: string,
  ramadanInfo: { startDate: string; endDate: string },
): number {
  const subDate = new Date(subscriptionDate).getTime();
  const startDate = new Date(ramadanInfo.startDate).getTime();
  const endDate = new Date(ramadanInfo.endDate).getTime();

  if (subDate < startDate) {
    // Subscription starts before Ramadan
    return 0;
  } else if (subDate > endDate) {
    // Subscription starts after Ramadan
    return 0;
  } else {
    // Subscription starts during Ramadan
    const lastDay = Math.min(subDate, endDate);
    const diff = Math.abs(lastDay - startDate);
    return Math.floor(diff / 86400000) + 1;
  }
}

//startDate and endDate must include time and timezone, e.g. 2024-06-07T23:00:00+00:00
//subscriptionDate is a string as this is how it's stored in the SubscriptionProvider
//returns the day of the challenge, given the start and end dates of a challenge
//returns 0 or challengeLength if the challenge has finished
export function getChallengeDay(
  startDate: Date,
  endDate: Date,
  challengeLength = 0,
  subscriptionDate?: string,
): number {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  //incorrect parameters were passed
  if (startMoment.isAfter(endMoment)) {
    console.warn('Incorrect parameters were passed');
    return 0;
  }

  const now = moment();
  let referenceMoment = now;

  //subscribed user's reference starts from subscription date
  if (subscriptionDate) {
    referenceMoment = moment(subscriptionDate);
  }

  if (referenceMoment.isBefore(startMoment)) {
    return 1;
  }

  //returns challengeLength or 0, allowing the calling function a truthy check on the return value to use the value accordingly
  if (referenceMoment.isAfter(endMoment)) {
    return challengeLength;
  }

  // Calculate the difference in milliseconds and convert to days
  const millisecondsDiff = referenceMoment.diff(startMoment);
  const challengeDay = Math.ceil(millisecondsDiff / (1000 * 60 * 60 * 24)) + 1;

  //protects against erroneous input
  if (challengeLength && challengeDay > challengeLength) {
    return challengeLength;
  }

  return challengeDay;
}

export function handlePageScroll(isScrollFrozen: boolean) {
  if (isScrollFrozen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
}

// Heap util function. Used for triggering custom event.
export function heapTrack(event: HeapEvents, metadata?: HeapTrackMetadata) {
  const { heap } = window as any;
  if (!heap) {
    console.error('Heap is not setup');
    return;
  }
  return heap.track(event, metadata);
}

// Heap util function. Used for identifying user on Heap. User is going to be passed on each heap event.
export function heapIdentify(user: current_user_result | undefined) {
  const { heap } = window as any;
  if (!heap) {
    console.error('Heap is not setup');
    return;
  }
  if (!user) {
    console.error('Heap identification failed. User undefined.');
    return;
  }
  heap.identify(user['user-id']);
  heap.addUserProperties({
    email: user.email,
    firstName: user['first-name'],
    lastName: user['last-name'],
    username: user.username,
  });
}
const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export const timeAgo = (dateTime: DateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export async function imageToBlob(imageUrl: string): Promise<Blob> {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return blob;
}

export const blobToDownloadFile = (data: Blob, filename?: string) => {
  const fileURL = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = fileURL;
  if (filename) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatTimeFromSeconds = (
  timeInSeconds: number,
  postfixWord = 'remaining',
) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;

  let formattedTime = '';
  if (minutes > 1) {
    formattedTime += `${minutes} minutes`;
  } else if (minutes === 1) {
    formattedTime += `${minutes} minute`;
  }

  if (seconds > 0) {
    if (formattedTime) formattedTime += ' and ';
    formattedTime += `${
      seconds < 10 ? '0' : ''
    }${seconds} seconds ${postfixWord}`;
  }

  return formattedTime;
};

/**
 *
 * @param num number to be formatted
 * @param decimalPlaces number of decimal places to show
 * @returns string formatted number
 * @example numberToFormattedString(1000.1234, 2) // "1,000.12"
 * @example numberToFormattedString(1000.1234, 0) // "1,000"
 * @example numberToFormattedString(1000.1234, 4) // "1,000.1234"
 */
export const numberToFormattedString = (num: number, decimalPlaces: number) => {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const generateAllDays = (entries: GivingMemberEntry[], days = 30) => {
  const generatedEntries: GivingMemberEntry[] = [];
  for (let i = 0; i < days; i++) {
    const entry = entries.find((e) => e.DayIndex === i);
    if (entry) {
      generatedEntries.push(entry);
    } else {
      generatedEntries.push({
        DayIndex: i,
        Donated: false,
        Label: '',
        SponsoredProjectID: [],
        DonatedProjectID: [],
      });
    }
  }

  return generatedEntries;
};

export const getGivingDayStatus = (
  entry: GivingMemberEntry,
  currentMonthDay: number,
) => {
  if (!entry.Donated && entry.DayIndex < currentMonthDay) {
    return 'missed';
  } else if (entry.Donated) {
    return 'filled';
  }
  return 'empty';
};

export const generateGivingStyles = (
  entries: GivingMemberEntry[],
  currentDhcDay: number,
  days: number,
) => {
  const getDayId = (entry: GivingMemberEntry) => {
    return `#day-${(entry.DayIndex + 1).toString().padStart(2, '0')}`;
  };

  return generateAllDays(entries, days)
    .map((entry) => {
      return `${getDayId(entry)} g.${getGivingDayStatus(entry, currentDhcDay)} {
        display: block !important;
        pointer-events: all !important;
        cursor: pointer !important;
      }`;
    })
    .join('\n');
};

export const getCampaignCards = async (
  entityIds: number[],
): Promise<CampaignCardProps[]> => {
  const apilg3BaseUrlOS = config().lg3OpenSearchUrl;

  const response = await fetch(
    `${apilg3BaseUrlOS}/cards/campaigns/by-id-list?limit=100&id_list=${entityIds.join(
      ',',
    )}`,
  );
  const { results } = await response.json();

  return results;
};

/**
 * Validates the donation and tip amounts based on specified rules and updates the UI accordingly.
 * It checks for non-numeric inputs, non-positive donation amounts, and whether the donation meets
 * the minimum required by the selected currency. Errors are communicated via a state setter.
 *
 * @param {number} donationAmount - The amount of money the donor wishes to donate.
 * @param {number} tipAmount - The amount of money the donor wishes to tip.
 * @param {InputCurrencies[]} currencies - An array of currency objects available for donation.
 * @param {string} donationAmountCurrency - The code of the currency used for the donation amount.
 * @param {string} donationAmountCurrencySymbol - The symbol of the currency used for the donation amount.
 * @param {(error: string) => void} setAmountError - A function to set the error message in the UI.
 * @param {(clicked: boolean) => void} [setClicked] - An optional function to update the UI's click state.
 * @returns {boolean} - Returns true if the validation passes, otherwise false.
 */
export const validateDonation = (
  donationAmount: number,
  tipAmount: number,
  currencies: InputCurrencies[],
  donationAmountCurrency: string,
  donationAmountCurrencySymbol: string,
  setAmountError: (error: string) => void,
  setClicked?: (clicked: boolean) => void,
): boolean => {
  // Check for non-numeric input
  if (isNaN(donationAmount) || isNaN(tipAmount)) {
    setAmountError('Please enter a donation amount to proceed.');
    setClicked && setClicked(false);
    return false;
  }

  // Check for non-positive donation amount
  if (donationAmount <= 0) {
    setAmountError('Donation amount must be greater than 0.');
    setClicked && setClicked(false);
    return false;
  }

  // Get the selected currency based on code and symbol
  const selectedCurrency = getCurrencyFromInputCurrencies(
    currencies,
    donationAmountCurrency,
  );

  // Check if donation amount meets the minimum required by the currency
  if (donationAmount < selectedCurrency.min) {
    setAmountError(
      `Minimum donation amount in ${getCurrencyCode(selectedCurrency)} is ${
        selectedCurrency.symbol
      }${selectedCurrency.min}.`,
    );
    setClicked && setClicked(false);
    return false;
  }

  // If all validations pass, set clicked to true and no error
  setClicked && setClicked(true);
  setAmountError('');
  return true;
};

export function getRandomLocation() {
  return randomLocations[Math.floor(Math.random() * randomLocations.length)];
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isMobileDevice(): boolean {
  const userAgent = navigator.userAgent;
  const isMobileScreen = window.matchMedia('(max-width: 767px)').matches;

  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    ) || isMobileScreen
  );
}

export const generateUniqueId = () => {
  return `id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

export const formatCurrencyFromBase = (
  amount: number,
  currencyCode: string,
  base = 100,
): CurrencyFormattedAmount => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  return {
    raw: amount / base,
    formatted: `${formatter.format(
      amount / base,
    )} ${currencyCode.toUpperCase()}`,
  };
};

export const getOrdinalDateIndicator = (day: number) => {
  const j = day % 10,
    k = day % 100;
  if (j === 1 && k !== 11) {
    return day + 'st';
  }
  if (j === 2 && k !== 12) {
    return day + 'nd';
  }
  if (j === 3 && k !== 13) {
    return day + 'rd';
  }
  return day + 'th';
};
// Helper for mapping 4.0 raw subscription data to SubscriptionObject model.
export function mapSubscriptionRawResponseToSubscription(
  subscription: SubscriptionRawResponse,
): SubscriptionObject {
  const {
    id,
    givingPlanId,
    donationAmount,
    donationAmountCurrency,
    tipAmount,
    tipAmountCurrency,
    givingCategories,
    cardId,
    cardType,
    unsubscribedAt,
    properties,
    createdAt,
    pausedAt,
    timeToNextCharge,
  } = subscription;
  const data: SubscriptionObject = {
    ...subscription,
    id,
    givingPlanId,
    donationAmount: parseFloat((donationAmount ?? '0') as string),
    donationAmountCurrency,
    donationAmountCurrencySymbol: getCurrencyFromInputCurrencies(
      currencies,
      donationAmountCurrency,
    ).symbol,
    tipAmount: parseFloat((tipAmount ?? '0') as string),
    tipAmountCurrency: tipAmountCurrency ?? 'USD',
    tipAmountCurrencySymbol: getCurrencyFromInputCurrencies(
      currencies,
      tipAmountCurrency ?? 'USD',
    ).symbol,
    givingCategories,
    cardId,
    cardType,
    unsubscribedAt,
    subscribedOn: createdAt,
    pausedAt,
    timeToNextCharge,
  };

  // These are only captured additionalAmount from Ramadan. At the moment of writing this code, additional amount is only appliacble for Ramadan.
  if (
    givingPlanId === ramadanChallange10Days ||
    givingPlanId === ramadanChallange30Days
  ) {
    data.additionalDonationAmount = parseFloat(
      (properties?.additionalLastTenDaysRamadanAmount ?? 0) as string, //Subsription prop general can be any string or number value so doing this just to be extra safe and also to bypass TS.
    );
    data.additionalTipAmount = parseFloat(
      (properties?.additionalLastTenDaysRamadanTipAmount ?? 0) as string, //Subsription prop general can be any string or number value so doing this just to be extra safe and also to bypass TS.
    );
  }

  return data;
}

export const isFlagSet = (value: number, flag: number): boolean => {
  return (value & flag) === flag;
};

export function camelCaseIfy(data: any) {
  forEach(data, function (val): any {
    if (this.isRoot) return;

    if (this.key === undefined) return;

    const camelKey = camelCase(this.key);

    if (camelKey === this.key) return;

    const camelPath = this.path.map((piece) => camelCase(piece));

    if (Array.isArray(val)) {
      const newVal = val.map((el) => camelCaseIfy(el));
      set(data, camelPath, newVal);
      this.remove(true);
      return;
    }

    if (this.isLeaf) {
      set(data, camelPath, val);
      this.remove(true);
    }
  });
  return data;
}
